import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {

  constructor(private _title:Title,private _meta:Meta){}

ngOnInit(){
  this._title.setTitle("Catavento Mandacaru - Galeria de fotos");
  this._meta.addTags([
    {name:"description",content:"Algumas fotos de clientes satisfeitos da Empresa Catavento Mandacaru"},
    {name:"keywords",content:"galeria, catavento, foto de catavento, catavento de energia, catavento de água, catavento mandacaru"}
  ]);
}
}
