import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-historia',
  templateUrl: './historia.component.html',
  styleUrls: ['./historia.component.css']
})
export class HistoriaComponent implements OnInit {

  constructor(private _title:Title,private _meta:Meta){}

ngOnInit(){
  this._title.setTitle("Catavento Mandacaru - História");
  this._meta.addTags([
    {name:"description",content:"Saiba como surgiu a empresa e como as tecnologias foram melhorando a vida do nordestino"},
    {name:"keywords",content:"perguntas frequentes, Quando o Catavento estiver jogando pouca água na caixa, Quando o vento é forte, o Catavento roda muito, mas a água é pouca e para de bombear, Quando tem vendo, mas o Catavento roda pouco, Quando não tem vento"}
  ]);
}
}
