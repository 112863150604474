import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  public contato : any = {
    "nome": "", "email": "", "telefone": "", "cidade_uf": "", "assunto": "", "mensagem": ""
  };

  public error: boolean = false;
  public errorMessage: any;
  public status: boolean = false;

  constructor(private http: HttpClient, private router: Router, private _title:Title,private _meta:Meta){}

ngOnInit(){
  this._title.setTitle("Catavento Mandacaru - Contato");
  this._meta.addTags([
    {name:"description",content:"Fale com a Empresa Catavento Mandacaru, utiliza o Cavalo Mecânico (equipamento criado, desenvolvido e adaptado para o Catavento de bombeio d’água"},
    {name:"keywords",content:"contato mandacaru, catavento como fazer, catavento energia eólica como fazer , catavento fotografia, cataventos, catavento água, catavento puxando água, catavento para água, catavento d'água, catavento bombeamento de agua, catavento energia, catavento energia eólica"}
  ]);
}

  sendMessage(){
    let isNumeric = function(obj){
      return !Array.isArray( obj ) && (obj - parseFloat( obj ) + 1) >= 0;
    }

    if(this.contato.nome.length <= 0){
      this.error = true;
      this.errorMessage = 'Preencha seu nome';
    }else if(this.contato.telefone.length <= 0){
      this.error = true;
      this.errorMessage = 'Preencha seu telefone';
    }else if(isNumeric(this.contato.telefone)){
      this.error = true;
      this.errorMessage = 'Utilize apenas números por favor';
    }else if(this.contato.mensagem.length <= 0){
      this.error = true;
      this.errorMessage = 'Preencha o campo mensagem';
    }else{
      this.error = false;
      this.http.post('https://mybelt.com.br/sites/mandacaru/sendMailMandacaru', this.contato).subscribe();
      this.contato.nome = "";
      this.contato.email = "";
      this.contato.telefone = "";
      this.contato.cidade_uf = "";
      this.contato.assunto = "";
      this.contato.mensagem = "";
      this.status = true;
      this.errorMessage = 'Recebemos sua mensagem, logo retornaremos. Obrigado :)';
    }

  }

}
