import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

constructor(private _title:Title,private _meta:Meta){}

ngOnInit(){
  this._title.setTitle("Catavento Mandacaru - Energia Eólica do Nordeste");
  this._meta.addTags([
    {name:"description",content:"A Empresa Catavento Mandacaru, utiliza o Cavalo Mecânico (equipamento criado, desenvolvido e adaptado para o Catavento de bombeio d’água"},
    {name:"keywords",content:"catavento como fazer, catavento energia eólica como fazer , catavento fotografia, cataventos, catavento água, catavento puxando água, catavento para água, catavento d'água, catavento bombeamento de agua, catavento energia, catavento energia eólica"}
  ]);
}

}
