import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})
export class ProdutosComponent implements OnInit {

  constructor(private route: ActivatedRoute, private _title:Title,private _meta:Meta){}

ngOnInit(){
  this._title.setTitle("Catavento Mandacaru - Produtos");
  this._meta.addTags([
    {name:"description",content:"Os principais produtos da Empresa Catavento Mandacaru estão disponiveis para compra em todo o Brasil"},
    {name:"keywords",content:"catavento, mandacaru, bomba manual de água, catavento de energia eólica, catavento para bombear água, Cavalo Mecânico ou Alavanca de Arquimedes, Bomba elefante"}
  ]);
}
}
