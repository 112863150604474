import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private _title:Title,private _meta:Meta){}

ngOnInit(){
  this._title.setTitle("Catavento Mandacaru - Perguntas Frequentes");
  this._meta.addTags([
    {name:"description",content:"Perguntas frequentes da Empresa Catavento Mandacaru"},
    {name:"keywords",content:"perguntas frequenstes, Quando o Catavento estiver jogando pouca água na caixa, Quando o vento é forte, o Catavento roda muito, mas a água é pouca e para de bombear, Quando tem vendo, mas o Catavento roda pouco, Quando não tem vento"}
  ]);
}
}
